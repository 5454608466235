import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, inject, Input } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../../../../../environments/environment';
import { take } from 'rxjs';

@Component({
  selector: 'logout-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logout-button.component.html',
  styleUrl: './logout-button.component.scss'
})

export class LogoutButtonComponent {
  @Input() public size: 'small' | 'normal' | 'large' = 'normal';

  private http = inject(HttpClient);
  private auth = inject(AuthService);
  private doc = inject(DOCUMENT);

  public handleLogout(): void {

    this.http.post(`${environment.apiUrl}/usuarioExterno/logout`, {}, { withCredentials: true })
    .pipe(take(1))
    .subscribe({
      next: (response: any) => {
        console.log(response);

        this.auth.logout({
          logoutParams: {
            returnTo: this.doc.location.origin,
          },
        });

      },
      error: (error: HttpErrorResponse) => {
        throw new Error(error.message);
      }
    });

  }
}
