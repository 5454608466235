<div class="page-layout" *ngIf="isAuth0Loading$ | async; else auth0Loaded">
  <div class="d-flex justify-content-center align-items-center vh-100">
    <div class="spinner-border text-oxxo-yellow" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>
</div>

<ng-template #auth0Loaded>
<div
  (mousemove)="onUserAction()"
  (click)="onUserAction()"
  (keypress)="onUserAction()"
>
  <externos-header></externos-header>

  <main class="bg-surface-secondary pt-4"  >
    <router-outlet class="d-none"></router-outlet>
  </main>

  <externos-footer></externos-footer>
</div>
</ng-template>
