import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { InicioPageComponent } from './domains/inicio/pages/inicio-page/inicio-page.component';
import { CallbackComponent } from './domains/shared/components/callback/callback.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full',
  },
  {
    path: 'inicio',
    title: 'Inicio',
    component: InicioPageComponent,
  },
  {
    path: 'login',
    redirectTo: 'inicio',
    pathMatch: 'full',
  },
  {
    path: 'mis-inmuebles',
    title: 'Mis inmuebles',
    loadComponent: () => import('./domains/dashboard/pages/dashboard-page/dashboard-page.component').then(m => m.DashboardPageComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'mis-inmuebles/inmueble/:id',
    title: 'Mi Propiedad',
    loadComponent: () => import('./domains/datos-inmueble/pages/visualizacion-datos/visualizacion-datos.component').then(m => m.VisualizacionDatosComponent),
    loadChildren: () => import('./domains/datos-inmueble/datos-inmueble.routes').then(m => m.INMUEBLES_ROUTES),
    canActivate: [AuthGuard],
  },
  {
    path: 'alta-inmuebles',
    title: 'Alta de inmuebles',
    loadComponent: () => import('./domains/alta-de-inmueble/pages/alta-inmueble/alta-inmueble.component').then(m => m.AltaInmuebleComponent),
  },
  {
    path: 'alta-inmuebles/persona-fisica-2',
    title: 'Persona Física paso 2',
    loadComponent: () => import('./domains/alta-de-inmueble/pages/persona-fisica-2/persona-fisica-2.component').then(m => m.PersonaFisica2Component),
  },
  {
    path: 'alta-inmuebles/persona-fisica-3',
    title: 'Persona Física paso 3',
    loadComponent: () => import('./domains/alta-de-inmueble/pages/persona-fisica-3/persona-fisica-3.component').then(m => m.PersonaFisica3Component),
  },
  {
    path: 'alta-inmuebles/persona-fisica-4',
    title: 'Persona Física paso 4',
    loadComponent: () => import('./domains/alta-de-inmueble/pages/persona-fisica-4/persona-fisica-4.component').then(m => m.PersonaFisica4Component),
  },
  {
    path: 'alta-inmuebles/persona-fisica-5',
    title: 'Persona Física paso 5',
    loadComponent: () => import('./domains/alta-de-inmueble/pages/persona-fisica-5/persona-fisica-5.component').then(m => m.PersonaFisica5Component),
  },
  {
    path: 'alta-inmuebles/persona-moral-2',
    title: 'Persona Moral paso 2',
    loadComponent: () => import('./domains/alta-de-inmueble/pages/persona-moral-2/persona-moral-2.component').then(m => m.PersonaMoral2Component),
  },
  {
    path: 'alta-inmuebles/persona-moral-3',
    title: 'Persona Moral paso 3',
    loadComponent: () => import('./domains/alta-de-inmueble/pages/persona-moral-3/persona-moral-3.component').then(m => m.PersonaMoral3Component),
  },
  {
    path: 'alta-inmuebles/persona-moral-4',
    title: 'Persona Moral paso 4',
    loadComponent: () => import('./domains/alta-de-inmueble/pages/persona-moral-4/persona-moral-4.component').then(m => m.PersonaMoral4Component),
  },
  {
    path: 'alta-inmuebles/persona-moral-5',
    title: 'Persona Moral paso 5',
    loadComponent: () => import('./domains/alta-de-inmueble/pages/persona-moral-5/persona-moral-5.component').then(m => m.PersonaMoral5Component),
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'confirmar-email',
    title: 'Confirmar email',
    loadComponent: () => import('./domains/shared/pages/confirmar-email/confirmar-email.component').then(m => m.ConfirmarEmailComponent),
  },
  {
    path: '**',
    redirectTo: '/inicio',
    pathMatch: 'full'
  }
];
