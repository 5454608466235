import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginButtonComponent } from '../../../shared/components/login-button/login-button.component';
import { SignupButtonComponent } from '../../../shared/components/signup-button/signup-button.component';
import { LogoutButtonComponent } from '../../../shared/components/logout-button/logout-button.component';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-inicio-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LoginButtonComponent,
    SignupButtonComponent,
    LogoutButtonComponent
  ],
  templateUrl: './inicio-page.component.html',
  styleUrl: './inicio-page.component.scss'
})

export class InicioPageComponent {
  private auth = inject(AuthService);
  public isAuthenticated$ = this.auth.isAuthenticated$
}
