import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { inject } from '@angular/core';
import { IdleService } from './idle.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalInfoComponent } from '../components/modal-info/modal-info.component';

export const sessionInterceptor: HttpInterceptorFn = (req, next) => {
  const modalService = inject(NgbModal);
  const idleService = inject(IdleService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        const modalInfo = modalService.open(ModalInfoComponent, { backdrop: 'static', keyboard: false });
        modalInfo.componentInstance.body = 'Ya cuenta con una sesión activa. Favor de cerrarla si desea acceder en otro dispositivo.';
        modalInfo.componentInstance.redirects = false;
        setTimeout(() => {
          modalInfo.close();
          idleService.logout();
        }, 6000);
      }
      if (error.status === 403) {
        const modalInfo = modalService.open(ModalInfoComponent, { backdrop: 'static', keyboard: false });
        modalInfo.componentInstance.body = 'Por inactividad se ha cerrado su sesión. Favor de volver a ingresar.';
        modalInfo.componentInstance.redirects = false;
        setTimeout(() => {
          modalInfo.close();
          idleService.logout();
        }, 6000);
      }
      return throwError(() => error);
    })
  );
};
