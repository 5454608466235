<footer class="container-fluid pt-5">
  <ul class="list-inline d-flex justify-content-center gap-3 align-items-center mb-5">
    <li class="p-2 lh-1 list-inline-item">
      <a 
        class="link-dark link-opacity-50 text-decoration-none text-white lh-1"
        href="https://www.oxxo.com/aviso-privacidadintegral-expansaion"
        target="_blank"
      >
        Política de Privacidad
      </a>
    </li>
    <li class="p-2 lh-1 list-inline-item">
      <a
        class="link-dark link-opacity-50 text-decoration-none text-white lh-1"
        href="https://www.oxxo.com/terminos-y-condiciones-identidad-del-titular-del-sitio-web"
        target="_blank"
      >
        Términos & Condiciones
      </a>
    </li>
    <li class="list-inline-item lh-1 p-2 ms-auto">
      <button
        class="btn btn-oxxo-yellow text-white rounded-5"
        (click)="openVerticallyCentered(content)"
      >
        ¡Contáctanos!
      </button>
    </li>
  </ul>

  <hr class="border-2 border-oxxo-yellow opacity-75 mb-4"/>

  <p class="text-white d-flex justify-content-center">© FEMSA 2024 - Todos los derechos reservados</p>
</footer>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center fw-semibold">Contáctanos</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body fs-5 fw-semibold text-center">
    <p>{{emailContacto}}</p>
  </div>
</ng-template>
