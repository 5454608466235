<button
  class="btn btn-content-secondary text-white fw-semibold rounded-5"
  (click)="handleLogout()"
  [ngClass]="{
    'btn-sm': size === 'small',
    'btn-lg': size === 'large',
    '': size === 'normal'
  }"
>
  Cerrar Sesión
</button>
