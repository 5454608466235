<div
  class="container-fluid py-5 d-flex flex-column gap-4 align-items-center justify-content-center"
>  
  <ng-template #unAuthenticated>
  <div class="row align-items-center">
    <div class="col-6 col-sm-3 justify text-start fw-bold">
      <h3>¡Bienvenido!</h3>
      <h6 class="fw-bold">
        ¿QUIÉNES SOMOS?
      </h6>
      <p
        class="fw-bold small-size-text"
      >
        A partir de 2020 Somos Inmobiliaria 78 “la inmobiliaria comercial inteligente”. Formamos parte de FEMSA Comercio, empresa líder en México y América latina Fundada en 1978. Somos especialistas inmobiliarios en dar atención y servicio personalizado a cada uno de nuestros clientes y  arrendadores a través de un equipo multidisciplinario enfocado en la compra, venta y renta de inmuebles.
      </p>
    </div>
    <div class="col-6 col-sm-3 text-start justify">
      <h6 class="fw-bold">
        Misión:
      </h6>
      <p>
      Optimizarte el proceso de conexión, negociación y cierre de contratos para tu propiedad con alguna de las unidades de negocio OXXO/FEMSA y clientes estratégicos, a través de una <b class="fw-bold">experiencia en línea confiable, segura e intuitiva.</b>
      </p>
    </div>
    <div class="col-6 col-sm-3 text-start">
      <h6 class="fw-bold">
        Visión:
      </h6>
      <p>
      Ser la plataforma digital por elección que conecte a vendedores, compradores y arrendatarios con las mejores oportunidades inmobiliarias, simplificándoles el proceso mediante herramientas tecnológicas garantizándoles eficiencia, información clara y soporte personalizado.
      </p>
    </div>
    <div class="col-6 col-sm-3 text-start">
      <div class="div-login">
        <h6 class=" fw-bold">
          Oxxo en
        </h6>
        <h6 class="fw-bold">
          tu terreno
        </h6>
        <p>
          <b class="fw-bold">
            Proposito: 
          </b>
          Facilitar el proceso de oferta y comercialización de tu propiedad con OXXO/FEMSA de manera directa y efectiva, acelerando los tiempos de respuesta.
        </p>
      </div>
      <div class="d-flex justify-content-center gap-4">
        <login-button></login-button>
        <signup-button></signup-button>
      </div>
    </div>
  </div>
  </ng-template>
  <ng-container *ngIf="isAuthenticated$ | async; else unAuthenticated">
    <h1 class="text-center fw-bold">¡Bienvenido!</h1>
    <logout-button></logout-button>
  </ng-container>
</div>
