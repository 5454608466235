import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ExternosHeaderComponent } from '../header/header.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { SharedPropiedadesService } from 'shared-lib';

@Component({
  selector: 'callback',
  standalone: true,
  imports: [CommonModule, ExternosHeaderComponent],
  templateUrl: './callback.component.html',
  styleUrl: './callback.component.scss'
})

export class CallbackComponent implements OnInit {
  private http = inject(HttpClient);
  private endpoint = `${environment.apiUrl}/usuarioExterno/login`;
  private propiedadesService = inject(SharedPropiedadesService);

  private login() {
    return this.http.post(this.endpoint, {}, { withCredentials: true });
  }

  ngOnInit() {
    this.propiedadesService.isLoading.next(true);
    this.login().subscribe({
      next: (response: any) => {
        console.log(response);
        this.propiedadesService.isLoading.next(false);
      },
      error: (error: HttpErrorResponse) => {
        this.propiedadesService.isLoading.next(false);
        throw new Error(error.error.message);
      }
    });
  }
}
