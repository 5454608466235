import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { ExternosFooterComponent } from './domains/shared/components/footer/footer.component';
import { ExternosHeaderComponent } from './domains/shared/components/header/header.component';
import { AuthService } from '@auth0/auth0-angular';
import { IdleService } from './domains/shared/services/idle.service';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { ModalInfoComponent } from './domains/shared/components/modal-info/modal-info.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    ExternosHeaderComponent,
    ExternosFooterComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent {
  private modalService = inject(NgbModal);
  private auth = inject(AuthService);
  public idleService = inject(IdleService);

  isAuth0Loading$ = this.auth.isLoading$;
  private isAuthenticated: boolean = false;
  private idleSubscription?: Subscription;

  ngOnInit() {
    this.headLinks();
    // Inicia el servicio para comprobar la inactividad del usuario
    this.idleSubscription = this.idleService.idleState.subscribe((isIdle) => {
      if (isIdle) {
        this.auth.isAuthenticated$.subscribe((Authenticated) => {
          this.isAuthenticated = Authenticated;
          console.log(this.isAuthenticated)
        });
        if (this.isAuthenticated) {
          const modalInfo = this.modalService.open(ModalInfoComponent, { backdrop: 'static', keyboard: false });
          modalInfo.componentInstance.body = 'Por inactividad se ha cerrado su sesión. Favor de volver a ingresar.';
          modalInfo.componentInstance.redirects = false;
          setTimeout(() => {
            modalInfo.close();
            this.idleService.logout();
          }, 6000);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }

  public onUserAction() {
    this.idleService.resetTimer();
  }

  // Método para agregar los links de preconexión y el script de Google Maps al head del documento
  private headLinks() {
    const head = document.getElementsByTagName('head')[0];

    const preconnectLink = document.createElement('link');
    preconnectLink.rel = 'preconnect';
    preconnectLink.href = environment.imgStorage;
    head.appendChild(preconnectLink);

    const googleMapsScript = document.createElement('script');
    const googleMapsApiUrl = `https://maps.googleapis.com/maps/api/js?key=${environment.mapsApiKey}&libraries=places&language=es-419`
    googleMapsScript.src = googleMapsApiUrl;
    head.appendChild(googleMapsScript);
  }
}
