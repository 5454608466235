import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { LogoutButtonComponent } from '../logout-button/logout-button.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'externos-header',
  standalone: true,
  imports: [RouterModule, CommonModule, LogoutButtonComponent, NgOptimizedImage],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})

export class ExternosHeaderComponent {
  private auth = inject(AuthService);
  public isAuthenticated$ = this.auth.isAuthenticated$;
  public user$ = this.auth.user$;
  public isMenuOpen = false;
  public logoExternos: string = environment.logoExternos;
}
