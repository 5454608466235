import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { routes } from './app.routes';
import { environment } from '../environments/environment';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEsMX from '@angular/common/locales/es-MX';
import { sessionInterceptor } from './domains/shared/services/session.interceptor';

registerLocaleData(localeEsMX, 'es-MX');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([authHttpInterceptorFn, sessionInterceptor])),
    provideAuth0({
      ...environment.auth0,
      httpInterceptor: {
        // Lista de endpoints a los que se les aplicará el interceptor de autenticación de Auth0
        allowedList: [
          `${environment.apiUrl}/usuarioExterno/login`,
          `${environment.apiUrl}/usuarioExterno/dashboard`,
          `${environment.apiUrl}/usuarioExterno/logout`,
          `${environment.apiUrl}/estatus/visualizar`,
          `${environment.apiUrl}/comentario/crear`,
          `${environment.apiUrl}/comentario/visualizar`,
          `${environment.apiUrl}/comentario/leido`,
          `${environment.apiUrl}/valoresFijos/consultar`,
          `${environment.apiUrl}/bitacoraEstatus/consultar`,
          `${environment.apiUrl}/propiedad/detalles`,
          `${environment.apiUrl}/propiedad/crear`,
          `${environment.apiUrl}/propiedad/actualizar`,
          `${environment.apiUrl}/propiedad/retirarPropiedad`,
          `${environment.apiUrl}/propietarioFisico/crear`,
          `${environment.apiUrl}/propietarioFisico/actualizar`,
          `${environment.apiUrl}/propietarioMoral/crear`,
          `${environment.apiUrl}/propietarioMoral/actualizar`,
          `${environment.apiUrl}/documento/actualizar`,
          `${environment.apiUrl}/documento/cargar`,
          `${environment.apiUrl}/imagen/cargar`,
          `${environment.apiUrl}/imagen/eliminar`,
        ]
      }
    }),
    [{ provide: LOCALE_ID, useValue: 'es-MX'}]
  ]
};
