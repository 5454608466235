import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'signup-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './signup-button.component.html',
  styleUrl: './signup-button.component.scss'
})

export class SignupButtonComponent {
  private auth = inject(AuthService);

  public handleSignUp(): void {
    this.auth.loginWithRedirect({
      appState: {
        target: "/mis-inmuebles",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: 'signup',
      },
    });
  }
}
