import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, Subject, Subscription, interval, take, throttle } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class IdleService {
  private idleSubject = new Subject<boolean>();
  private timeout = 600;
  private lastActivity?: Date;
  private idleCheckInterval = 10;
  private idleSubscription?: Subscription;

  private http = inject(HttpClient);
  private auth = inject(AuthService);
  private doc = inject(DOCUMENT);

  constructor() {
    this.resetTimer();
    this.startWatching();
  }

  get idleState(): Observable<boolean> {
    return this.idleSubject.asObservable();
  }

  private startWatching() {
    this.idleSubscription = interval(this.idleCheckInterval * 1000)
    .pipe(throttle(() => interval(1000)))
    .subscribe(() => {
      const now = new Date();

      if (now.getTime() - this.lastActivity?.getTime()! > this.timeout * 1000) {
        this.idleSubject.next(true);
      }
    });
  }

  public resetTimer() {
    this.lastActivity = new Date();
    this.idleSubject.next(false);
  }

  public stopWatching() {
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }

  public logout(): void {
    this.http.post(`${environment.apiUrl}/usuarioExterno/logout`, {}, { withCredentials: true })
    .pipe(take(1))
    .subscribe({
      next: (response: any) => {
        console.log(response);

        this.auth.logout({
          logoutParams: {
            returnTo: this.doc.location.origin,
          },
        });

      },
      error: (error: HttpErrorResponse) => {
        console.log(error.status);
        throw new Error(error.error.message);
      }
    });
  }

}
