import { Component, inject, TemplateRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'externos-footer',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})

export class ExternosFooterComponent {
  public modalService = inject(NgbModal);

  public emailContacto: string = "Si tienes alguna duda o comentario sobre nuestro portal, por favor envíanos un correo a ganacontuinmueble@oxxo.com";

  openVerticallyCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true });
  }
}
